import { createMuiTheme } from '@material-ui/core/styles'
import styled from 'styled-components'
import Input from '@material-ui/core/Input'
import StepButton from '@material-ui/core/StepButton'
import { Stepper, Grid } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { SketchPicker } from 'react-color'
import { SHOP_ID } from '../../../brikl-config'
import { SingleSelect } from 'react-select-material-ui'

const regex = /^(#(?:[0-9a-f]{2}){2,4}|#[0-9a-f]{3}|(?:rgba?|hsla?)\((?:\d+%?(?:deg|rad|grad|turn)?(?:,|\s)+){2,3}[\s\/]*[\d\.]+%?\))$/i
let primaryColor = '#FFFFFF'
let secondaryColor = '#000000'
try {
  if (__PRIMARY_COLOR__) {
    primaryColor = __PRIMARY_COLOR__
  }
} catch (error) {}
try {
  if (__SECONDARY_COLOR__) {
    secondaryColor = __SECONDARY_COLOR__
  }
} catch (error) {}

//Color set here
export const primary =
  primaryColor && regex.test(primaryColor) ? primaryColor : '#FFFFFF'
export const secondary =
  secondaryColor && regex.test(secondaryColor) ? secondaryColor : '#000000'

// LEGACY THEME FOR OBS PAGES

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    h1: {
      letterSpacing: 2.4,
      fontFamily:
        SHOP_ID === 'vermarc'
          ? 'BiomeStd-Light, sans-serif'
          : SHOP_ID === 'bodywearlab'
          ? 'Work Sans, sans-serif'
          : SHOP_ID === 'OBS'
          ? 'news-gothic-std'
          : ['Roboto', '"Helvetica Neue"', 'Arial', 'Jaldi'],
      fontSize: '45px',
      lineHeight: '4rem'
    },
    h2: {
      letterSpacing: 1,
      fontFamily:
        SHOP_ID === 'vermarc'
          ? 'BiomeStd-Light, sans-serif'
          : SHOP_ID === 'bodywearlab'
          ? 'Work Sans, sans-serif'
          : ['news-gothic-std', 'Roboto', '"Helvetica Neue"', 'Arial', 'Jaldi'],
      fontSize: '23px'
    },
    h3: {
      letterSpacing: 1,
      fontFamily:
        SHOP_ID === 'vermarc'
          ? 'BiomeStd-Light, sans-serif'
          : SHOP_ID === 'bodywearlab'
          ? 'Work Sans, sans-serif'
          : ['news-gothic-std', 'Roboto', '"Helvetica Neue"', 'Arial', 'Jaldi'],
      fontSize: '19px'
    },
    h4: {
      fontFamily:
        SHOP_ID === 'vermarc'
          ? 'BiomeStd-Light, sans-serif'
          : SHOP_ID === 'bodywearlab'
          ? 'Work Sans, sans-serif'
          : ['news-gothic-std', 'Roboto', '"Helvetica Neue"', 'Arial', 'Jaldi'],
      fontSize: '18px',
      lineHeight: '19px',
      letterSpacing: '1px',
      textTransform: 'uppercase'
    },
    h5: {
      fontFamily:
        SHOP_ID === 'vermarc'
          ? 'BiomeStd-Light, sans-serif'
          : SHOP_ID === 'bodywearlab'
          ? 'Work Sans, sans-serif'
          : ['news-gothic-std', 'Roboto', '"Helvetica Neue"', 'Arial', 'Jaldi'],
      fontSize: '1rem'
    },
    h6: {
      letterSpacing: 2.4,
      fontFamily:
        SHOP_ID === 'vermarc'
          ? 'BiomeStd-Light, sans-serif'
          : SHOP_ID === 'bodywearlab'
          ? 'Work Sans, sans-serif'
          : ['news-gothic-std', 'Roboto', '"Helvetica Neue"', 'Arial', 'Jaldi']
    },
    subtitle1: {
      letterSpacing: 1,
      fontSize: '16px',
      lineHeight: '17px',
      fontFamily:
        SHOP_ID === 'vermarc'
          ? 'BiomeStd-Light, sans-serif'
          : SHOP_ID === 'bodywearlab'
          ? 'Work Sans, sans-serif'
          : ['news-gothic-std', 'Roboto', '"Helvetica Neue"', 'Arial', 'Jaldi'],
      textTransform: 'uppercase'
    },
    body1: {
      fontSize: '16px',
      fontWeight: SHOP_ID === 'vermarc' ? 300 : 400,
      fontFamily:
        SHOP_ID === 'vermarc'
          ? `'Exo 2', sans-serif`
          : SHOP_ID === 'bodywearlab'
          ? 'Work Sans, sans-serif'
          : ['news-gothic-std', 'Roboto', '"Helvetica Neue"', 'Arial', 'Jaldi']
    }
  },
  palette: {
    primary: {
      main: primary || '#FFF'
    },
    secondary: {
      main: secondary
    }
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none'
      }
    },
    MuiButton: {
      root: {
        fontFamily:
          SHOP_ID === 'vermarc'
            ? 'BiomeStd-Light, sans-serif'
            : SHOP_ID === 'bodywearlab'
            ? 'Work Sans, sans-serif'
            : ['news-gothic-std', 'Roboto', '"Helvetica Neue"', 'Arial'],
        fontSize: '12px'
      }
    },
    MuiCard: {
      root: {
        padding: '16px'
      }
    },
    MuiTabs: {
      root: {
        background: '#464646',
        minHeight: '40px',
        color: '#fff',
        width: '100%'
      },
      indicator: {
        backgroundColor: 'transparent'
      }
    },
    MuiTooltip: {
      popper: {
        opacity: 1
      }
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        fontWeight: 700,
        minWidth: '0px',
        padding: 0,
        width: '40px',
        fontFamily: '"Roboto", Helvetica, Arial, sans-serif',
        minHeight: '40px',
        height: '40px',
        color: '#ffffff',
        flex: '1 !important',
        borderBottom: '2px solid transparent',
        '&$tabSelected': {
          fontWeight: 700,
          borderBottom: '2px solid' + primary,
          color: '#ffffff'
        },
        '&:focus': {
          borderBottom: '2px solid' + primary,
          color: '#ffffff'
        },
        '&$selected': {
          borderBottom: '2px solid' + primary,
          color: '#ffffff'
        }
      }
    },
    MuiListItem: {
      root: {
        textTransform: 'uppercase',
        marginTop: 8,
        fontFamily:
          SHOP_ID === 'vermarc'
            ? 'BiomeStd-Light, sans-serif'
            : SHOP_ID === 'bodywearlab'
            ? 'Work Sans, sans-serif'
            : SHOP_ID === 'OBS'
            ? 'news-gothic-std'
            : ['Roboto', '"Helvetica Neue"', 'Arial']
      }
    },
    MuiPaper: {
      root: {
        fontFamily:
          SHOP_ID === 'vermarc'
            ? 'BiomeStd-Light, sans-serif'
            : SHOP_ID === 'bodywearlab'
            ? 'Work Sans, sans-serif'
            : SHOP_ID === 'OBS'
            ? 'news-gothic-std'
            : ['Roboto', '"Helvetica Neue"', 'Arial']
      }
    },
    MuiInput: {
      root: {
        borderRadius: 4,
        position: 'relative',
        border: 'none',
        fontSize: 14,
        transition: 'none',
        fontFamily:
          SHOP_ID === 'vermarc'
            ? 'BiomeStd-Light, sans-serif'
            : SHOP_ID === 'bodywearlab'
            ? 'Work Sans, sans-serif'
            : SHOP_ID === 'OBS'
            ? 'news-gothic-std'
            : ['Roboto', '"Helvetica Neue"', 'Arial']
      },
      underline: {
        width: '100%',
        '&:before': {
          display: 'none'
        },
        '&:after': {
          display: 'none'
        }
      }
    },
    MuiNativeSelect: {
      root: {
        'label + &': {
          marginTop: 8
        }
      },
      select: {
        borderRadius: 4,
        position: 'relative',
        border: 'none',
        fontSize: 14,
        padding: '8px',
        transition: 'none',
        paddingRight: 32,
        // Use the system font instead of the default Roboto font.
        fontFamily:
          SHOP_ID === 'vermarc'
            ? 'BiomeStd-Light, sans-serif'
            : SHOP_ID === 'bodywearlab'
            ? 'Work Sans, sans-serif'
            : SHOP_ID === 'OBS'
            ? 'news-gothic-std'
            : ['Roboto', '"Helvetica Neue"', 'Arial'],
        '&:focus': {
          borderRadius: 4
        }
      }
    },
    MuiInputBase: {
      root: {
        'label + &': {
          marginTop: 8
        },
        width: '100%'
      },
      input: {
        borderRadius: 4,
        position: 'relative',
        border: '1px solid #ced4da',
        width: '100%',
        padding: '10px 12px'
      }
    },
    MuiTextField: {
      root: {
        'label + &': {
          marginTop: 8
        },
        width: '100%'
      }
    },
    MuiToggleButtonGroup: {
      root: {
        fontSize: '10px !important'
      },
      input: {
        fontSize: 10
      },
      select: {
        fontSize: 10
      },
      icon: {
        right: '8px !important'
      }
    }
  }
})

export const MuiSelect = styled(SingleSelect)`
&& {
  div{
    border: none !important;
    background: #ffffff;
    padding-bottom: 1px;
    padding-top: 1px;
  }
  div:hover {
    border-bottom: 0px
  }
  div:focus{
    border-bottom: 0px
  }
  border: none;
  background: transparent;
  height: 40px;
  font-weight: 400;
  line-height: 16px;
  fontSize: 12px;
  width: 100%;
  border-bottom: 0px;
  &:hover {
    border-bottom: 0px;
  }
  input:before: {
    border-bottom: 0px;
  }
  input:after {
    border-bottom: 0px;
  }
  .control:hover
    border-bottom: 0px;
  }
}
`

export const InputBase = styled(Input)`
  && {
    &:after {
      border-bottom: 2px solid #ad4a3b;
    }
  }
`

export const InputTitle = styled(Input)`
  && {
    color: #111111;
    width: 100%;
    input {
      text-align: center;
      padding: 6px 0px;
      &:placeholder {
        color: #111111;
      }
    }
    &:before {
      border-bottom: 2px solid #111111;
    }
    &:after {
      border-bottom: 2px solid #ad4a3b;
    }
  }
`

export const InputLabelBase = styled(Select)`
  && {
    color: #ffffff;
    width: 100%;
    margin-top: 0px;
    font-size: 12px;
    padding: 0px 8px;
    svg {
      color: #ffffff !important;
    }
    div {
      padding: 0px;
      width: 80px;
      height: 26px;
      padding-top: 3px;
    }
    input {
      padding: 0px;
      height: 24px;
      font-size: 12px;
      color: #ffffff;
      &:placeholder {
        color: #ffffff;
      }
    }
    &:hover {
      border-bottom: 2px solid #333333;
    }
    &:before {
      border-bottom: 2px solid #333333;
    }
    &:after {
      border-bottom: 2px solid #333333;
    }
  }
`

export const StepButtonBase = styled(StepButton)`
  && {
    padding: 10px 0px;
    margin: 0px;
    border-radius: 10px,
    color: #ffffff;
    &:hover{
        cursor: pointer;
        span {
          color: #ad4a3b;
        }
      }
    }
    svg{
      display: none;
    }
    span {
      color: #ffffff;
      border: 0px !important;
    }
    div{
      display: none;
    }
    }
  }
`

export const StepperBase = styled(Stepper)`
  && {
    div {
      padding: 0px 4px;
      margin-right: 0px;
    }
    span {
      color: #ffffff;
      border: 0px !important;
    }
  }
`

export const SelectBase = styled(Select)`
  && {
    padding: 4px 8px 2px;
    background: #525252;
    border-radius: 4px;
    color: #ffffff;
    font-size: 12px;
    svg {
      color: #ffffff;
    }
    &:after {
      display: none;
    }
    &:before {
      display: none;
    }
  }
`

export const SelectLight = styled(Select)`
  && {
    padding: 4px 8px 2px;
    background: #f0f0f0;
    border-radius: 4px;
    color: #333;
    font-size: 12px;
    svg {
      color: #333;
    }
    &:after {
      display: none;
    }
    &:before {
      display: none;
    }
  }
`

export const TextFields = styled(TextField)`
  && {
    padding: 4px 8px 2px;
    border-radius: 4px;
  }
`

export const Inputs = styled(Input)`
  && {
    padding: 4px 8px 2px;
    background: #525252;
    border-radius: 4px;
    &:after {
      display: none;
    }
    &:before {
      display: none;
    }
  }
`

export const InputsLight = styled(Input)`
  && {
    padding: 4px 8px 2px;
    background: #f0f0f0;
    border-radius: 4px;
    &:after {
      display: none;
    }
    &:before {
      display: none;
    }
  }
`

export const MaxWidth = styled(Grid)`
  && {
    margin: auto;
    padding: 40px 32px 40px;
    min-width: 1200px;
    max-width: 1200px;
    @media only screen and (max-width: 1200px) {
      min-width: 980px;
      max-width: 1200px;
    }
    @media only screen and (max-width: 980px) {
      min-width: 100%;
    }
  }
`

export const Notes = styled.span`
  font-size: 12px;
`

export const SketchPickerBase = styled(SketchPicker)`
  && {
    box-shadow: none !important;
    span {
      color: #000 !important;
    }
  }
`
