// const store = require('store')
const localForage = require('localforage')

export const setCurrency = (currency: string) => {
  try {
    window.localStorage.setItem('CUR', currency)
    // localForage.setItem('CUR', currency)
  } catch (error) {
    console.error('failed to set')
  }
}
export const getCurrency = (): string | null => {
  try {
    if (typeof window !== 'undefined') {
      return window.localStorage.getItem('CUR') &&
        window.localStorage?.getItem('CUR')?.indexOf('id') !== -1
        ? JSON.parse(window.localStorage.getItem('CUR')).id
        : window.localStorage.getItem('CUR')
    } else {
      return null
    }
    // localForage.getItem('CUR', function(err, value) {
    //   if (err) {
    //     return null
    //   }
    //   if (value) {
    //     return value
    //   } else {
    //     if (window && window.localStorage.getItem('CUR')) {
    //       const x = window.localStorage.getItem('CUR')
    //       setCurrency(x)
    //       return x
    //     } else {
    //       return null
    //     }
    //   }
    // })
  } catch (error) {
    console.error('failed to get CUR', error)
    return null
  }
}

export const setLanguage = (language: string) => {
  try {
    window.localStorage.setItem('LANG', language)
    // localForage.setItem('LANG', language)
  } catch (error) {
    console.error('failed to set')
  }
}
export const getLanguage = (): string | null => {
  try {
    return window.localStorage.getItem('LANG')?.indexOf('id') !== -1
      ? JSON.parse(window.localStorage.getItem('LANG')).id
      : window.localStorage.getItem('LANG')
    // localForage.getItem('LANG', function(err, value) {
    //   if (err) {
    //     return null
    //   }
    //   if (value) {
    //     return value
    //   } else {
    //     if (window && window.localStorage.getItem('LANG')) {
    //       const x = window.localStorage.getItem('LANG')
    //       setLanguage(x)
    //       return x
    //     } else {
    //       return null
    //     }
    //   }
    // })
  } catch (error) {
    console.error('failed to set LANG', error)
    return null
  }
}

export const setCountry = (country: string) => {
  try {
    window.localStorage.setItem('COUNTRY', country)
    // localForage.setItem('COUNTRY', country)
  } catch (error) {
    console.error('failed to set')
  }
}
export const getCountry = (): string | null => {
  try {
    return process.env.GATSBY_BRIKL_SHOP_ID === 'vermarc'
      ? 'BE'
      : window.localStorage.getItem('COUNTRY')?.indexOf('id') !== -1
      ? JSON.parse(window.localStorage.getItem('COUNTRY')).id
      : window.localStorage.getItem('COUNTRY')
    // localForage.getItem('COUNTRY', function(err, value) {
    //   if (err) {
    //     return null
    //   }
    //   if (value) {
    //     return value
    //   } else {
    //     if (window && window.localStorage.getItem('COUNTRY')) {
    //       const x = window.localStorage.getItem('COUNTRY')
    //       setCountry(x)
    //       return x
    //     } else {
    //       return null
    //     }
    //   }
    // })
  } catch (error) {
    console.error('failed to set COUNTRY', error)
    return null
  }
}

export const setDefaultCountry = (defaultCountry: string) => {
  try {
    window.localStorage.setItem('DEFAULTCOUNTRY', defaultCountry)
    // localForage.setItem('DEFAULTCOUNTRY', defaultCountry)
  } catch (error) {
    console.error('failed to set')
  }
}
export const getDefaultCountry = (): string | null => {
  try {
    return window.localStorage.getItem('DEFAULTCOUNTRY')?.indexOf('id') !== -1
      ? JSON.parse(window.localStorage.getItem('DEFAULTCOUNTRY')).id
      : window.localStorage.getItem('DEFAULTCOUNTRY')
    // localForage.getItem('DEFAULTCOUNTRY', function(err, value) {
    //   if (err) {
    //     return null
    //   }
    //   if (value) {
    //     return value
    //   } else {
    //     if (window && window.localStorage.getItem('DEFAULTCOUNTRY')) {
    //       const x = window.localStorage.getItem('DEFAULTCOUNTRY')
    //       setDefaultCountry(x)
    //       return x
    //     } else {
    //       return null
    //     }
    //   }
    // })
  } catch (error) {
    console.error('failed to set DEFAULTCOUNTRY', error)
    return null
  }
}

export const setCollection = (collection: string) => {
  try {
    window.localStorage.setItem('COLLECTION', collection)
    // localForage.setItem('COLLECTION', collection)
  } catch (error) {
    console.error('failed to set')
  }
}
export const getCollection = (): string | null => {
  try {
    return window.localStorage.getItem('COLLECTION')?.indexOf('id') !== -1
      ? JSON.parse(window.localStorage.getItem('COLLECTION')).id
      : window.localStorage.getItem('COLLECTION')
    // localForage.getItem('COLLECTION', function(err, value) {
    //   if (err) {
    //     return null
    //   }
    //   if (value) {
    //     return value
    //   } else {
    //     if (window && window.localStorage.getItem('COLLECTION')) {
    //       const x = window.localStorage.getItem('COLLECTION')
    //       setCollection(x)
    //       return x
    //     } else {
    //       return null
    //     }
    //   }
    // })
  } catch (error) {
    // console.error('failed to get COLLECTION', error)
    return null
  }
}

export const setGuestOrg = (guestOrg: string) => {
  try {
    // window.localStorage.setItem('GUESTORG', guestOrg)
    localForage.setItem('GUESTORG', guestOrg)
  } catch (error) {
    console.error('failed to set')
  }
}
export const getGuestOrg = (shopId): string | null => {
  try {
    return localForage.getItem('GUESTORG', function (err, value) {
      if (err) {
        return null
      }
      if (value) {
        if (shopId) {
          if (value.indexOf(shopId) !== -1) {
            return value
          } else {
            localForage.removeItem('GUESTORG')
            return null
          }
        } else {
          return value
        }
      } else {
        if (
          typeof window !== `undefined` &&
          window &&
          window.localStorage.getItem('GUESTORG')
        ) {
          if (
            window.localStorage.getItem('GUESTORG').indexOf('id') !== -1 &&
            JSON.parse(window.localStorage.getItem('GUESTORG'))
          ) {
            const oldValue = JSON.parse(window.localStorage.getItem('GUESTORG'))
              .id
            setGuestOrg(oldValue)
            return oldValue
          } else {
            if (window.localStorage.getItem('GUESTORG').indexOf('id') === -1) {
              const oldValue2 = window.localStorage.getItem('GUESTORG')
              setGuestOrg(oldValue2)
              return oldValue2
            } else {
              return null
            }
          }
        } else {
          return null
        }
      }
    })
  } catch (error) {
    console.error('failed to set GUESTORG', error)
    return null
  }
}

export const setOrg = (org: string) => {
  try {
    window.localStorage.setItem('ORG', org)
    // localForage.setItem('ORG', org)
  } catch (error) {
    console.error('failed to set')
  }
}
export const getOrg = (): string | null => {
  try {
    return window.localStorage.getItem('ORG')?.indexOf('id') !== -1
      ? JSON.parse(window.localStorage.getItem('ORG')).id
      : window.localStorage.getItem('ORG')
    // localForage.getItem('ORG', function(err, value) {
    //   if (err) {
    //     return null
    //   }
    //   if (value) {
    //     return value
    //   } else {
    //     if (window && window.localStorage.getItem('ORG')) {
    //       const x = window.localStorage.getItem('ORG')
    //       setOrg(x)
    //       return x
    //     } else {
    //       return null
    //     }
    //   }
    // })
  } catch (error) {
    console.error('failed to set ORG', error)
    return null
  }
}

export const setShopId = (shopId: string) => {
  try {
    // window.localStorage.setItem('adminShopId', shopId)
    localForage.setItem('adminShopId', shopId)
  } catch (error) {
    console.error('failed to set')
  }
}
export const getShopId = (): string | null => {
  try {
    return localForage.getItem('adminShopId', function (err, value) {
      if (err) {
        return null
      }
      if (value) {
        return value
      } else {
        if (window && window.localStorage.getItem('adminShopId')) {
          const x = window.localStorage.getItem('adminShopId')
          setShopId(x)
          return x
        } else {
          return null
        }
      }
    })
  } catch (error) {
    console.error('failed to set adminShopId', error)
    return null
  }
}

export const setTeamstorePw = (orgId: string, teamstoreId: string) => {
  try {
    localForage.setItem(teamstoreId, orgId)
  } catch (error) {
    console.error('failed to set')
  }
}
export const getTeamstorePw = (teamstoreId: string) => {
  try {
    return localForage.getItem(teamstoreId, function (err, value) {
      if (err) {
        return null
      }
      if (value) {
        return value
      } else {
        if (window && window.localStorage.getItem(teamstoreId)) {
          const x = window.localStorage.getItem(teamstoreId)
          setTeamstorePw(x, teamstoreId)
          return x
        } else {
          return null
        }
      }
    })
  } catch (error) {
    console.error('failed to set teamstore', error)
    return null
  }
}
