const SHOP_ID = process.env.GATSBY_BRIKL_SHOP_ID
exports.SHOP_ID =
  process.env.GATSBY_BRIKL_IS_DASHBOARD === 'yes' ? null : SHOP_ID
exports.STORETYPE = process.env.GATSBY_BRIKLSTORETYPE
exports.API_STAGE =
  process.env.GATSBY_API_STAGE === 'production' ? 'production' : 'staging'
exports.MEDIA_CDN_URL = `https://${
  process.env.GATSBY_API_STAGE === 'production'
    ? ''
    : process.env.GATSBY_API_STAGE === 'local'
    ? 'dev.'
    : process.env.GATSBY_API_STAGE + '.'
}cdn.mybrikl.com`

exports.IMGIX_URL =
  process.env.GATSBY_API_STAGE === 'production'
    ? 'https://cdn-media-brikl.imgix.net'
    : process.env.GATSBY_API_STAGE === 'staging'
    ? 'https://staging-cdn-media-brikl.imgix.net'
    : 'https://dev-cdn-media-brikl.imgix.net'

const GATSBY_API_STAGE = process.env.GATSBY_API_STAGE
const API_URL = process.env.GATSBY_API_STAGE
  ? //mybrikl
    SHOP_ID === 'vermarc' &&
    process.env.GATSBY_USE_ALB_API === 'yes' &&
    GATSBY_API_STAGE === 'production'
    ? 'http://gql-alb-915145721.ap-southeast-1.elb.amazonaws.com/'
    : GATSBY_API_STAGE === 'production'
    ? 'https://api.mybrikl.com/graphql'
    : GATSBY_API_STAGE === 'staging'
    ? 'https://staging.api.mybrikl.com/graphql'
    : // 'https://staging.api.ap-southeast-1.mybrikl.com/graphql'
    GATSBY_API_STAGE === 'staging-frankfurt'
    ? 'https://staging.api.frankfurt.mybrikl.com/graphql'
    : GATSBY_API_STAGE === 'dev'
    ? 'https://s8dgeqd232.execute-api.ap-southeast-1.amazonaws.com/dev/graphql'
    : 'http://localhost:1212/graphql'
  : 'ERROR'

const PUBLIC_CDN_URL = process.env.GATSBY_API_STAGE
  ? // mybrikl
    GATSBY_API_STAGE === 'production'
    ? 'https://cdn.api.mybrikl.com/graphql'
    : GATSBY_API_STAGE === 'staging'
    ? 'https://staging.cdn.api.mybrikl.com/graphql'
    : GATSBY_API_STAGE === 'staging-frankfurt'
    ? 'https://staging.api.frankfurt.mybrikl.com/graphql'
    : GATSBY_API_STAGE === 'dev'
    ? 'https://s8dgeqd232.execute-api.ap-southeast-1.amazonaws.com/dev/graphql'
    : 'http://localhost:1212/graphql'
  : 'ERROR'
const CONTENT_AS_A_SERVICE_API = process.env.GATSBY_API_STAGE
  ? process.env.GATSBY_BRIKLSTORETYPE === 'MARKET'
    ? //market
      GATSBY_API_STAGE === 'production'
      ? 'TBD'
      : GATSBY_API_STAGE === 'staging'
      ? 'TBD'
      : GATSBY_API_STAGE === 'staging-frankfurt'
      ? 'TBD'
      : 'http://localhost:4000/graphql'
    : // mybrikl
    GATSBY_API_STAGE === 'production'
    ? 'TBD'
    : GATSBY_API_STAGE === 'staging'
    ? 'TBD'
    : GATSBY_API_STAGE === 'staging-frankfurt'
    ? 'TBD'
    : 'http://localhost:4000/graphql'
  : 'ERROR'

if (API_URL === 'ERROR') {
  throw new Error(
    'API Url not set for SHOP_ID ' +
      SHOP_ID +
      ' and GATSBY_API_STAGE ' +
      GATSBY_API_STAGE
  )
}
if (PUBLIC_CDN_URL === 'ERROR') {
  throw new Error(
    'PUBLIC_CDN_URL Url not set for SHOP_ID ' +
      SHOP_ID +
      ' and GATSBY_API_STAGE ' +
      GATSBY_API_STAGE
  )
}
if (CONTENT_AS_A_SERVICE_API === 'ERROR') {
  throw new Error(
    'CONTENT_AS_A_SERVICE_API Url not set for SHOP_ID ' +
      SHOP_ID +
      ' and GATSBY_API_STAGE ' +
      GATSBY_API_STAGE
  )
}
exports.CONTENT_AS_A_SERVICE_API = CONTENT_AS_A_SERVICE_API
exports.PUBLIC_CDN_URL = PUBLIC_CDN_URL
exports.API_URL = API_URL
