/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const Sentry = require('@sentry/browser')
const { SHOP_ID } = require('./brikl-config')
const { setCountry } = require('./src/helper/storage')
try {
  require('./shop-imports/' + SHOP_ID + '-require.js')
} catch (error) {}

exports.onServiceWorkerUpdateReady = () => {
  // try {
  //   if (typeof window !== `undefined`) {
  //     const answer = window.confirm(
  //       `This application has been updated. ` +
  //         `Reload to display the latest version?`
  //     );
  //     if (answer === true) {
  //       window.location.reload();
  //     }
  //   }
  // } catch (error) {}
}
exports.onClientEntry = async function(_, pluginParams) {
  try {
    if (typeof window !== `undefined`) {
      fetch('/locale.json')
        .then(res => res.json())
        .then(data => {
          if (data) {
            setCountry(data.countryCode)
          }
        })
        .catch(err =>
          console.error('Error fetching data from /locale.json endpoint', err)
        )
    }
  } catch (error) {
    console.error('onClientEntry.error', error)
  }
  try {
    if (process.env.GATSBY_API_STAGE === 'production') {
      if (process.env.GATSBY_BRIKL_IS_DASHBOARD === 'yes') {
        Sentry.init({
          dsn: 'https://03a1c07a52af4ee9995896333096b126@sentry.io/1540535',
          release: __GIT_COMMITHASH__,
          environment: process.env.GATSBY_API_STAGE,
          integrations: integrations => {
            return integrations.filter(
              integration => integration.name !== 'Breadcrumbs'
            )
          }
        })
      } else {
        // LogRocket.init('wgiurt/brikl-shop-client')
        Sentry.init({
          dsn: 'https://03c665c8952c4a5d83138191098fb6dd@sentry.io/1277070',
          release: __GIT_COMMITHASH__,
          environment: process.env.GATSBY_API_STAGE,
          integrations: integrations => {
            return integrations.filter(
              integration => integration.name !== 'Breadcrumbs'
            )
          }
        })
      }

      window.Sentry = Sentry
    }
  } catch (error) {}

  // if (process.env.NODE_ENV === 'production') {
  //   require.ensure(['@sentry/browser'], function(require) {
  //     const Sentry = require('@sentry/browser');
  //     Sentry.init({
  //       dns: 'https://03c665c8952c4a5d83138191098fb6dd@sentry.io/1277070',
  //       release: __GIT_COMMITHASH__,
  //       environment: process.env.GATSBY_API_STAGE
  //     });
  //     window.Sentry = Sentry;
  //   });
  // }
}
exports.onRouteUpdate = () => {
  if (typeof window !== `undefined`) {
    window.scrollTo(0, 0)
  }
}

exports.shouldUpdateScroll = args => {
  return false
}

// exports.wrapRootElement = ({ element }) => (
//   <ApolloProvider client={client}>{element}</ApolloProvider>
// );
const React = require('react')

exports.wrapRootElement = ({ element }) => {
  // Internet Explorer 6-11
  let isIE = /*@cc_on!@*/ false
  try {
    if (typeof document !== `undefined`) {
      isIE = !!document.documentMode
    }
  } catch (error) {}
  // Edge 20+
  let isEdge = false
  try {
    if (typeof window !== `undefined`) {
      isEdge = !isIE && !!window.StyleMedia
    }
  } catch (error) {}
  if (isIE) {
    return (
      <div
        style={{
          height: '90vh',
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <h2
          style={{
            fontSize: '1.5em',
            lineHeight: '1.3em'
          }}
        >
          Sorry, your web browser is not supported.
          <br /> Please use another browser
        </h2>
      </div>
    )
  } else {
    return element
  }
}
